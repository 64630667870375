<template>
    <div class="form-registration" v-if="translates && translates[langUrl]">
        <div class="form-registration__title">{{ title }}</div>
        <div>
            <ValidationObserver ref="validator_1" slim>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-if="phoneConfirm === false"
                        v-model="firstname"
                        :error-messages="errors[0]"
                        class="form"
                        :placeholder="translates[langUrl].placeholder_firstname[lang]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-if="phoneConfirm === false"
                        v-model="lastname"
                        :error-messages="errors[0]"
                        class="form"
                        type="tel"
                        autocomplete="off"
                        :placeholder="translates[langUrl].placeholder_lastname[lang]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-if="phoneConfirm === false"
                        :error-messages="errors[0]"
                        v-model="phone"
                        @keyup.enter="savePhone"
                        autocomplete="off"
                        :value="phone"
                        class="form"
                        type="tel"
                        :placeholder="translates[langUrl].placeholder_phone[lang]"
                    />
                </ValidationProvider>
            </ValidationObserver>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <BaseTextField
                    v-if="phoneConfirm === false"
                    v-model="email"
                    :error-messages="errors[0]"
                    class="form"
                    :placeholder="translates[langUrl].placeholder_email[lang]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <BaseTextField
                    v-if="phoneConfirm === false"
                    v-model="social"
                    :error-messages="errors[0]"
                    class="form"
                    :placeholder="translates[langUrl].placeholder_social[lang]"
                    @keyup.enter="send"
                />
            </ValidationProvider>
            <BaseButton
                v-if="phoneConfirm === false"
                class="button-fill"
                style="width:100%; margin-top: 12px;"
                @click="savePhone">{{ translates[langUrl].button_savePhone[lang] }}
            </BaseButton>
        </div>
        <div style="display: flex; flex-direction: column">
            <ValidationObserver ref="validator_2" slim>
                <ValidationProvider v-slot="{ errors }" rules="required|code">
                    <div
                        v-if="phoneConfirm === true"
                        class="form-registration__number"
                    >{{ translates[langUrl].phoneConfirm_part1[lang] }} {{ phoneFormatted }} {{ translates[langUrl].phoneConfirm_part2[lang] }}</div>
                    <BaseTextField
                        v-if="phoneConfirm === true"
                        v-model="code"
                        pattern="\d*"
                        :label="translates[langUrl].label_code[lang]"
                        counter="4"
                        @keyup.enter="confirmPhone"
                        :error-messages="errors[0]"
                    />
                    <div v-if="secondsLeft && phoneConfirm" class="form-registration__send-code">{{ translates[langUrl].sendCode_part1[lang] }} <span class="form-registration__send-code__timer">{{ translates[langUrl].sendCode_part2[lang] }} {{ prettyCounter }}</span></div>
                    <div
                        v-if="!secondsLeft && phoneConfirm"
                        class="form-registration__send-code_active"
                        @click="phoneRepeatSubmit">{{ translates[langUrl].button_resendCode[lang] }}</div>
                </ValidationProvider>
            </ValidationObserver>
            <BaseButton
                v-if="phoneConfirm === true"
                class="button-fill"
                style="width:100%; margin-bottom: 12px;"
                @click="confirmPhone">{{ translates[langUrl].button_confirmPhone[lang] }}
            </BaseButton>
        </div>
    </div>
</template>
<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { isMobile } from '@/helpers';

    import BaseTextField from './BaseTextField.vue'
    import BaseTextArea from './BaseTextArea.vue'
    import BaseButton from './BaseButton.vue'
    import BaseCheckbox from './BaseCheckbox.vue'

    export default {
        name: 'FormRegistration',
        components: {
            BaseTextField,
            BaseTextArea,
            BaseButton,
            BaseCheckbox,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            title: {
                type: String,
            },
        },
        data: () => ({
            phone: '',
            phoneFormatted: '',
            code: '',
            email: '',
            emailCode: '',
            firstname: '',
            lastname: '',
            social: '',
            phoneConfirm: false,
            secondsLeft: 0,
            langUrl: 'formRegistration'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            canCodeRequest() {
                return this.model.phone.replace(/[^\d]/g, '').length === 10 && this.model.name;
            },
            prettyCounter() {
                if (this.secondsLeft < 1) {
                    return '';
                }
                const minutes = Math.floor(this.secondsLeft / 60);
                const seconds = this.secondsLeft - minutes * 60;

                return `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
            },
        },
        methods: {
            async clear () {
            },
            async savePhone() {
                const valid = await this.$refs['validator_1'].validate();
                if(valid) {
                    this.phoneFormatted = '+' + this.phone.replace(/[^\d]/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d*)/, '$1 $2 $3 $4 $5');
                    await store.dispatch('auth/login', { phone: '+' + this.phone.replace(/[^\d]/g, ''), blogger: true });
                    this.phoneConfirm = true;
                }
                await this.startTimer();
            },
            async phoneRepeatSubmit() {
                this.startTimer();
                await store.dispatch('auth/login', { phone: '+' + this.phone.replace(/[^\d]/g, ''), blogger: true });
            },
            async confirmPhone() {
                const valid = await this.$refs['validator_2'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    if(
                        this.user.blogger &&
                        this.user.firstname && this.user.firstname !== '' &&
                        this.user.lastname && this.user.lastname !== '' &&
                        this.user.email && this.user.email !== '' &&
                        this.user.social && this.user.social !== ''
                    ) {
                        await this.$router.push({ name: 'blogger-audience' });
                    } else {
                        store.commit('auth/SET_USER', { ...this.user, email: this.email, firstname: this.firstname, lastname: this.lastname, social: this.social, blogger: true });
                        await store.dispatch('auth/save');
                        await this.$router.push({ name: 'blogger-audience' });
                    }
                }
            },
            startTimer() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }

                this.secondsLeft = 120;

                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            },
        }
    }

</script>
<style lang="scss">
.form-registration {
    font-family: Inter;
    font-style: normal;
    width: 450px;
    // height: 569px;
    padding: 35px 32px;
    background: #fff;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 12px;
    @media all and (max-width: 768px) {
        width: 100%;
        // height: 561px;
        padding: 20px 20px 25px;
    }
    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 140%;
        color: #273155;
        margin-bottom: 32px;
    }
    
    &__send-code {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin: 7px auto 20px 10px;
        &__timer {
            opacity: 0.5;
        }

        &__number {
            padding-top: 10px;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.02em;
            opacity: 0.5;
            // padding-bottom: 10px;
        }
        &_active {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            text-decoration: underline;
            // opacity: 0.5;
            color: #3A4256;
            margin: 7px auto 20px 10px;
            cursor: pointer;
        }
    }
}
</style>
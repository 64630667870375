var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.translates && _vm.translates[_vm.langUrl])?_c('div',{staticClass:"form-registration"},[_c('div',{staticClass:"form-registration__title"},[_vm._v(_vm._s(_vm.title))]),_c('div',[_c('ValidationObserver',{ref:"validator_1",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.phoneConfirm === false)?_c('BaseTextField',{staticClass:"form",attrs:{"error-messages":errors[0],"placeholder":_vm.translates[_vm.langUrl].placeholder_firstname[_vm.lang]},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}):_vm._e()]}}],null,false,1941528230)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.phoneConfirm === false)?_c('BaseTextField',{staticClass:"form",attrs:{"error-messages":errors[0],"type":"tel","autocomplete":"off","placeholder":_vm.translates[_vm.langUrl].placeholder_lastname[_vm.lang]},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}):_vm._e()]}}],null,false,4255122698)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.phoneConfirm === false)?_c('BaseTextField',{staticClass:"form",attrs:{"error-messages":errors[0],"autocomplete":"off","value":_vm.phone,"type":"tel","placeholder":_vm.translates[_vm.langUrl].placeholder_phone[_vm.lang]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.savePhone.apply(null, arguments)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}):_vm._e()]}}],null,false,3347491658)})],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.phoneConfirm === false)?_c('BaseTextField',{staticClass:"form",attrs:{"error-messages":errors[0],"placeholder":_vm.translates[_vm.langUrl].placeholder_email[_vm.lang]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_vm._e()]}}],null,false,3431537894)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.phoneConfirm === false)?_c('BaseTextField',{staticClass:"form",attrs:{"error-messages":errors[0],"placeholder":_vm.translates[_vm.langUrl].placeholder_social[_vm.lang]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send.apply(null, arguments)}},model:{value:(_vm.social),callback:function ($$v) {_vm.social=$$v},expression:"social"}}):_vm._e()]}}],null,false,1637224774)}),(_vm.phoneConfirm === false)?_c('BaseButton',{staticClass:"button-fill",staticStyle:{"width":"100%","margin-top":"12px"},on:{"click":_vm.savePhone}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_savePhone[_vm.lang])+" ")]):_vm._e()],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('ValidationObserver',{ref:"validator_2",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required|code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.phoneConfirm === true)?_c('div',{staticClass:"form-registration__number"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].phoneConfirm_part1[_vm.lang])+" "+_vm._s(_vm.phoneFormatted)+" "+_vm._s(_vm.translates[_vm.langUrl].phoneConfirm_part2[_vm.lang]))]):_vm._e(),(_vm.phoneConfirm === true)?_c('BaseTextField',{attrs:{"pattern":"\\d*","label":_vm.translates[_vm.langUrl].label_code[_vm.lang],"counter":"4","error-messages":errors[0]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmPhone.apply(null, arguments)}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}):_vm._e(),(_vm.secondsLeft && _vm.phoneConfirm)?_c('div',{staticClass:"form-registration__send-code"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].sendCode_part1[_vm.lang])+" "),_c('span',{staticClass:"form-registration__send-code__timer"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].sendCode_part2[_vm.lang])+" "+_vm._s(_vm.prettyCounter))])]):_vm._e(),(!_vm.secondsLeft && _vm.phoneConfirm)?_c('div',{staticClass:"form-registration__send-code_active",on:{"click":_vm.phoneRepeatSubmit}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_resendCode[_vm.lang]))]):_vm._e()]}}],null,false,2226695371)})],1),(_vm.phoneConfirm === true)?_c('BaseButton',{staticClass:"button-fill",staticStyle:{"width":"100%","margin-bottom":"12px"},on:{"click":_vm.confirmPhone}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_confirmPhone[_vm.lang])+" ")]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }